import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { MarketInfoService } from '../services/MarketInfoService.service';
import { Subscription } from 'rxjs';
import { UserService } from '../services/User.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rental-menu',
  templateUrl: './rental-menu.component.html',
  styleUrls: ['./rental-menu.component.scss']
})
export class RentalMenuComponent implements OnInit{
  @Input() IsVendorLogin;
  marketId;
  imageUrl= "";
  vendorId=0;
  private subscriptions: Subscription[] = [];
  allowRegisterVendor = false;
  allowNewVendorBooking = false;
  activeRoute: string = '';
  vendorTerm = "Vendor";
  constructor(
    private route: ActivatedRoute,
    private userService:UserService,
    private router:Router,
    private marketInfoService:MarketInfoService,) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
    });
    this.getWebRentalSetting();
    this.getMarketLogo();  
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeRoute = this.router.url;
      }
    }); 
  }  

  getMarketLogo() {
    const timestamp = new Date().getTime();
    const sub = this.marketInfoService.getMarketLogo(this.marketId).subscribe((res: any) => {
      if (res) {
          this.imageUrl = res.MarketLogoBlobURL + `?timestamp=${timestamp}`;
      }
      else {
        this.imageUrl = '../../../assets/images/NoImage.jpg'
      }
    });
    this.subscriptions.push(sub);
  }

  getWebRentalSetting(){
    const sub = this.marketInfoService.getWebRentalSetting(this.marketId).subscribe((res: any) => {
      if (res) {
        this.allowRegisterVendor =res.VendorRegistration;
        this.allowNewVendorBooking = res.AllowNewVendorBooking;
        this.vendorTerm = res.WebRentalVendorTermText;
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  isActiveRoute(route: string): boolean {
    return this.router.url.includes(route);
  }

  changeRentalMenu(pagename) {
    var token = localStorage.getItem('token');
    if (token && this.router.url.includes("rent-online")) {
      if(pagename == "login"){
        return false;
      }
      Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Navigating to other page will log you out from the application. Are you sure you want to logout?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,

      }).then((result) => {
        if (result.value) {
          this.userService.clearToken();
          if (pagename == "newven-online") {
            this.router.navigate(['/' + pagename, this.marketId, this.vendorId]);
          } else
            this.router.navigate(['/' + pagename, this.marketId]);
        }
        else {
          return false;
        }
      })
    }
    else {
      if (pagename == "newven-online") {
        this.router.navigate(['/' + pagename, this.marketId, this.vendorId]);
      } else
        this.router.navigate(['/' + pagename, this.marketId]);
    }
  }

  checkVendorLoggedIn() {
    var token = localStorage.getItem('token');
    if (token && this.router.url.includes("rent-online"))
      return true;
    else
      return false;
  }

  logout() {
    Swal.fire({
      title: $localize`Confirm`,
      text: $localize`Are you sure you want to logout?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,

    }).then((result) => {
      if (result.value) {
        this.userService.clearToken();
        var url = '/login/' + this.marketId;
        this.router.navigate([url]);
      } else {
        return;
      }
    });
  }
}
