import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject,Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})

export class MarketInfoService {
  private baseURL: string;
  constructor(private http: HttpClient) {
    this.baseURL = environment.apiBaseUrl;
  }

  private marketIdSubject = new BehaviorSubject<any | null>(null);
  marketId$ = this.marketIdSubject.asObservable();

  setMarketId(id: any) {
    this.marketIdSubject.next(id);
  }

  getMarketId() {
    return this.marketIdSubject.asObservable();
  }

  public options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  getMarketDays(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetMarketDays?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketDays = res.body as any;
          return marketDays;
        }),
        catchError(this.handleError)
      );
  }

  getMarketLogo(marketId): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.baseURL}/MarketInfo/GetMarketLogo?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketLogo= res.body as any[];
          return marketLogo;
        }),
        catchError(this.handleError)
      );
  }


  getDailyMarketDays(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetDailyMarketDays?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketdays = res.body as any;
          return marketdays;
        }),
        catchError(this.handleError)
      );
  }

  getWeeklyMarketDays(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetWeeklyMarketDays?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketdays = res.body as any;
          return marketdays;
        }),
        catchError(this.handleError)
      );
  }

  getALLBoothsForLayoutALLRentalTypes(marketId, rentalTypesDatesModel): Observable<any> {
    const url = `${this.baseURL}/MarketInfo/GetALLBoothsForLayoutALLRentalTypes?marketId=` + marketId
    return this.http.post<any>(url, rentalTypesDatesModel, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getMonthlyMarketDays(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetMonthlyMarketDays?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketdays = res.body as any;
          return marketdays;
        }),
        catchError(this.handleError)
      );
  }

  getParts(marketId): Observable<boolean> {
    return this.http.get<any>(
        `${this.baseURL}/MarketInfo/GetParts?marketId=` + marketId,
        { observe: 'response' })
        .pipe(
          map(res => {
            const parts = res.body as any;
            return parts;
          }),
          catchError(this.handleError)
        );
   }

   getAllBoothsForDailyRentalTypeLayout(marketId,partId, selectedMarketDays): Observable<any> {
    const url = `${this.baseURL}/MarketInfo/GetAllBoothsForDailyRentalTypeLayout?partId=${partId}&marketId=${marketId}`
    return this.http.post<any>(url, selectedMarketDays, this.options)
      .pipe(
        map(res => {
          const response = res as any;
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getListOfValuesData(): Observable<any> {    
    return this.http.get<any>(
        `${this.baseURL}/MarketInfo/GetListOfValuesData`,
        { observe: 'response' })
        .pipe(
          map(res => {
            const result = res.body as any;
            return result;
          }),
          catchError(this.handleError)
        );
   }

   getLayoutFonts(marketId){
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetLayoutFonts?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
   }

   getWebRentalSetting(marketId): Observable<boolean> {
    return this.http.get<any>(
        `${this.baseURL}/MarketInfo/GetWebRentalSetting?marketId=` + marketId,
        { observe: 'response' })
        .pipe(
          map(res => {
            const parts = res.body as any;
            return parts;
          }),
          catchError(this.handleError)
        );
   }

   getWebRentalSettingDashboardSetting(marketId): Observable<boolean> {
    return this.http.get<any>(
        `${this.baseURL}/MarketInfo/GetWebRentalSettingDashboardSetting?marketId=` + marketId,
        { observe: 'response' })
        .pipe(
          map(res => {
            return res.body as any;
          }),
          catchError(this.handleError)
        );
   }

   getBuildingAreasInLayOut(partId,marketId){
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetBuildingAreasInLayOut?partId=${partId}&marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
   }

   getImages(){
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetImagesForLayout`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
   }

  getLayoutImages(partId, marketId) {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetLayoutImages?partId=${partId}&marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  getMarketDetails(marketId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetMarketDetails?marketId=` + marketId,
      { observe: 'response' })
      .pipe(
        map(res => {
          const marketDetails = res.body as any;
          return marketDetails;
        }),
        catchError(this.handleError)
      );
  }

  getCurrentMarketVendorGeneralSettings(marketId): Observable<boolean> {
    return this.http.get<any>(
      `${this.baseURL}/General/getCurrentMarketVendorGeneralSettings?marketId=${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const generalSettings = res.body as any;
          return generalSettings;
        }),
        catchError(this.handleError)
      );
  }

  GetAllMarketProducts(Id): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/Vendor/GetAllMarketProducts?marketId=${Id}`,
      { observe: 'response' })
      .pipe(
        map(res => {

          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  RegistrationFee(Id): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/MarketInfo/GetRegistrationFee?marketId=${Id}`,
      { observe: 'response' })
      .pipe(
        map(res => {

          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      const errMessage = error.error.message;
      // this.appinsights.logEvent(errMessage);
      return throwError(errMessage);
    }
    return throwError(error || 'Node.js server error');
  }
} 