<app-loader *ngIf="loaderIcon | async"></app-loader>
<div class="container-fluid">
    <form [formGroup]="rentalForm">
        <div class="row">
            <div class="col-lg-2 col-xl-2 alignMenu shadow text-left pt-2 px-1">
                <app-rental-menu IsVendorLogin="IsVendorLogin"></app-rental-menu>
            </div>
            <div class="col-lg-10 col-xl-10 bgMainSection bgBlue text-left">
                <div class="container mainContainer">
                    <div class="row">
                        <div class="col-5 text-left mt-4">
                            <label class="mainHeading mb-0">{{pageName}}</label>
                        </div>
                        <div class="col-7 text-right pl-0 mt-4">
                            <label class="mainHeading mb-0">{{marketName}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-center mt-3">
                            <label class="welcome">Welcome {{vendorName}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                            <button #btnRentOnline class="btn btnMain mr-3 mt-2" (click)="rentOnline()">Rent Online</button>
                            <button #ContactInfo class="btn btnMain mr-3 mt-2" (click)="contactInfo()">Current Contact
                                Information</button>
                            <button #btnLoginInfo class="btn btnMain mr-3 mt-2" (click)="LoginInfo()">Online Login
                                Info</button>
                            <button #btnBookings class="btn btnMain mr-3 mt-2" (click)="MyBookings()">My Bookings</button>
                        </div>
                    </div>
                    <div #divContactInfo class="bgWghite shadow mt-2"
                        [ngStyle]="{ display: showrentOnline ? 'block' : 'none' }">
                        <div class="container">
                            <div class="row mt-1 pl-4">
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0 mt-2" *ngIf="AllowBookingsDaily">
                                    <input type="radio" id="rdDaily" class="form-check-input" value="1" formControlName="rentalTypes" >
                                    <label for="rdDaily" class="form-check-label">Daily</label>
                                </div>
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0 mt-2" *ngIf="AllowBookingsWeekly">
                                    <input type="radio" id="rdWeekly" class="form-check-input" value="3" formControlName="rentalTypes" >
                                    <label for="rdWeekly" class="form-check-label">Weekly</label>
                                </div>
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0 mt-2" *ngIf="AllowBookingsMonthly">
                                    <input type="radio" id="rdMonthly" class="form-check-input" value="2" formControlName="rentalTypes" >
                                    <label for="rdMonthly" class="form-check-label">Monthly</label>
                                </div>
                                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-3 ml-lg-3 ml-xl-0 mt-2" *ngIf="AllowBookingsCw">
                                    <input type="radio" id="rdCombinedWeek" class="form-check-input" value="5" formControlName="rentalTypes" >
                                    <label for="rdCombinedWeek" class="form-check-label">Combined Weekly</label>
                                </div>
                                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-3 ml-lg-3 ml-xl-0 mt-2" *ngIf="AllowBookingsCm">
                                    <input type="radio" id="rdCombinedMonth" class="form-check-input" value="4" formControlName="rentalTypes" >
                                    <label for="rdCombinedMonth" class="form-check-label">Combined Monthly</label>
                                </div>
                            </div>
                            <div class="row mt-1 pl-2">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xl-12 ml-lg-12 ml-xl-0">
                                    <span *ngIf="this.rentalForm.value['rentalTypes'] == 1" style="color: red;font-size:12px;">Booths can be rented
                                        for single or multiple market days on a one time
                                        basis.</span>
                                    <span *ngIf="this.rentalForm.value['rentalTypes'] == 2" style="color: red;font-size:12px;">Booths can be rented for
                                        one market day per week on permanent monthly basis.</span>
                                    <span *ngIf="this.rentalForm.value['rentalTypes'] == 3" style="color: red;font-size:12px;">Booths can be rented for
                                        one market day in the week on permanent weekly basis.</span>
                                    <span *ngIf="this.rentalForm.value['rentalTypes'] == 4" style="color: red;font-size:12px;">Booth can be rented
                                        for all market days in the month on monthly basis.</span>
                                    <span *ngIf="this.rentalForm.value['rentalTypes'] == 5" style="color: red;font-size:12px;">Booths can be rented
                                        for all market days in the week on weekly basis.</span>                                
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-center">
                                    <hr class="my-2" />
                                </div>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '1'">
                                <app-dailyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)"></app-dailyrental-online>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '2'">
                                <app-monthlyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)"></app-monthlyrental-online>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '3'">
                                <app-weeklyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)"></app-weeklyrental-online>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '4'">
                                <app-combinedmonthlyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)"></app-combinedmonthlyrental-online>
                            </div>
                            <div *ngIf="this.rentalForm.value['rentalTypes'] == '5'">
                                <app-combinedweeklyrental-online (isFormDirty)="setDirtyFlag($event)" (isCancelClicked)="isCancelClicked($event)"></app-combinedweeklyrental-online>
                            </div>
                        </div>
                    </div>
                    <div #divOnlineAccess class="bgWghite shadow pt-2 mt-2"
                        [ngStyle]="{ display: showContactInfo ? 'block' : 'none' }">
                        <div class="container" formGroupName="contactInfoForm">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0"><span class="required">*</span>Name:</label>
                                        <input type="text" class="form-control" formControlName="firstName">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0"><span class="required">*</span>Last Name</label>
                                        <input type="text" class="form-control" formControlName="lastName">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">Company Name:</label>
                                        <input type="text" class="form-control" formControlName="companyName">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0"><span class="required">*</span>Street Address:</label>
                                        <input type="text" class="form-control" formControlName="address">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0"><span class="required">*</span>City</label>
                                        <input type="text" class="form-control" formControlName="city">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0"><span class="required">*</span>State/ Province</label>
                                        <select id="ddlState" class="form-control" *ngIf="showStateDropDown" tabindex="9" formControlName="state">
                                            <option *ngFor="let st of stateList" [value]="st.StateCode">{{st.StateCode}}
                                            </option>
                                        </select>
                                        <input type="text" *ngIf="!showStateDropDown" maxlength="2" tabindex="9" class="form-control"
                                            formControlName="state">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0"><span class="required">*</span>Country</label>
                                        <select id="ddlCountry" class="form-control" formControlName="country" tabindex="11" (change)="onCountryChange()">
                                            <option *ngFor="let ct of countryList" [value]="ct.Code">{{ct.Name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0"><span class="required">*</span>Zip/Postal Code</label>
                                        <ejs-maskedtextbox [mask]="ZipCodeMask" tabindex="13" name="ZipCode"
                                        id="ZipCode" formControlName="postalCode"></ejs-maskedtextbox>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">Work Phone</label>
                                        <ejs-maskedtextbox [mask]="PhoneNumberMask" tabindex="13" name="workPhone" id="workPhone"
                                            formControlName="workPhone" (keyup)="onKeyUp('workPhone')"></ejs-maskedtextbox>
                                        <small style="color: red;" *ngIf="showWorkPhoneError" i18n>Enter valid work
                                            phone number</small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0"><span class="required">*</span>Cell Phone</label>
                                        <ejs-maskedtextbox [mask]="PhoneNumberMask" tabindex="13" name="cellPhone" id="cellPhone"
                                            formControlName="cellPhone" (keyup)="onKeyUp('cellPhone')"></ejs-maskedtextbox>
                                        <small style="color: red;" *ngIf="showCellPhoneError" i18n>Enter valid cell
                                            phone number</small>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0"><span class="required">*</span>Email</label>
                                        <input type="text" class="form-control text-left" formControlName="email"
                                        [pattern]="emailIdsPattern" (keypress)="restrictSpace($event)">
                                        <div class="alert alert-danger"
                                            *ngIf="this.rentalForm.controls['contactInfoForm'].get('email').errors && ( this.rentalForm.controls['contactInfoForm'].get('email').dirty ||  this.rentalForm.controls['contactInfoForm'].get('email').touched)">
                                            <span *ngIf=" this.rentalForm.controls['contactInfoForm'].get('email').errors.pattern" i18n>
                                                Enter valid Email ID</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-right my-3">
                                    <button class="btn btn-outline-primary mr-1" (click)="cancelContactModification()">Cancel</button>
                                    <button class="btn btn-primary" (click)="UpdateVendor()" [disabled]="validateContactForm()">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #divLoginInfo class="bgWghite shadow pt-2 mt-2"
                        [ngStyle]="{ display: showLoginInfo ? 'block' : 'none' }">
                        <div class="container" formGroupName="onlineLoginForm">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*User Name</label>
                                        <input type="text" class="form-control" formControlName="onlineUserName">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*Password</label>
                                        <div class="input-group">
                                            <input [type]="passwordFieldType" class="form-control" formControlName="onlinePassword"
                                                placeholder="Enter Password" />
                                            <span class="input-group-text" (click)="togglePasswordVisibility()">
                                                <i [ngClass]="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">Confirm Password</label>
                                        <div class="input-group">
                                            <input [type]="passwordFieldType" class="form-control" formControlName="confirmPassword">
                                            <span class="input-group-text" (click)="togglePasswordVisibility()">
                                                <i [ngClass]="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*Security Question</label>
                                        <select id="ddlState" class="form-control" tabindex="9" formControlName="securityQuestions">
                                            <option *ngFor="let questions of securityQuestion" [value]="questions.ID">{{questions.SecurityQuestion}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-3">
                                    <div>
                                        <label class="mb-0">*Answer</label>
                                        <input type="text" class="form-control" formControlName="securityAnswer">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-right my-3">
                                    <button class="btn btn-outline-primary mr-1">Cancel</button>
                                    <button class="btn btn-primary" (click)="saveVendorLoginDetail()">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div #divBookings class="bgWghite shadow pt-2 mt-2"
                        [ngStyle]="{ display: showBookings ? 'block' : 'none' }" style="padding-bottom: 2rem;">
                        <div class="container">
                            <div class="row mt-1 pl-4">
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsDaily">
                                    <input type="radio" id="rdBookingsDaily" class="form-check-input" value="1" formControlName="bookingRentalTypes">
                                    <label for="rdBookingsDaily" class="form-check-label">Daily</label>
                                </div>
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsWeekly">
                                    <input type="radio" id="rdBookingsWeekly" class="form-check-input" value="3" formControlName="bookingRentalTypes">
                                    <label for="rdBookingsWeekly" class="form-check-label">Weekly</label>
                                </div>
                                <div class="col-md-6 col-lg-2 col-sm-12 col-xl-2 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsMonthly">
                                    <input type="radio" id="rdBookingsMonthly" class="form-check-input" value="2" formControlName="bookingRentalTypes">
                                    <label for="rdBookingsMonthly" class="form-check-label">Monthly</label>
                                </div>
                                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-3 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsCw">
                                    <input type="radio" id="rdBookingsCombinedWeek" class="form-check-input" value="5" formControlName="bookingRentalTypes">
                                    <label for="rdBookingsCombinedWeek" class="form-check-label">Combined Weekly</label>
                                </div>
                                <div class="col-md-6 col-lg-3 col-sm-12 col-xl-3 ml-lg-3 ml-xl-0" *ngIf="AllowBookingsCm">
                                    <input type="radio" id="rdBookingsCombinedMonth" class="form-check-input" value="4" formControlName="bookingRentalTypes">
                                    <label for="rdBookingsCombinedMonth" class="form-check-label">Combined Monthly</label>
                                </div>
                            </div>   
                            <div *ngIf="showBookings && (this.rentalForm.value['bookingRentalTypes'] == '1' || this.rentalForm.value['bookingRentalTypes'] == '4' || this.rentalForm.value['bookingRentalTypes'] == '5')">
                                <app-booking-details [rentalType]="this.rentalForm.value['bookingRentalTypes']" [marketId]="marketId" [vendorId]="vendorId"></app-booking-details>
                            </div>

                            <div *ngIf="showBookings && (this.rentalForm.value['bookingRentalTypes'] == '2' || this.rentalForm.value['bookingRentalTypes'] == '3')">
                                <app-booking-details-weekly-monthly [rentalType]="this.rentalForm.value['bookingRentalTypes']" [marketId]="marketId" [vendorId]="vendorId"></app-booking-details-weekly-monthly>
                            </div>
                        </div>
                    </div>
                    <div #divBookings class="bgWghite shadow pt-2 mt-2"
                        [ngStyle]="{ display: showReviewRegistration ? 'block' : 'none' }">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-2">
                                    <label class="mb-0">Registeration Fee</label>
                                    <br />
                                    <input type="text" class="form-control">
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4 text-left mt-2">
                                    <label class="mb-0">Registration Exp Date</label>
                                    <br />
                                    <ejs-datepicker format='MM/dd/yyyy' [allowEdit]=false></ejs-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-left">
                                    <div>
                                        <label class="mb-0">
                                            <input type="checkbox" id="chkRenewReg" class="CheckboxClass mt-3">
                                            <label class="chkAlign mb-0" for="chkRenewReg" style="margin-top: -27px !important">I authorize ABC Flea Market to charge this amount to my
                                                Credit Card. Upon authorization and full payment by the issuing card
                                                company, ABC Flea Market will submit a confirmation number, as verification
                                                of charged transaction, to the above named cardholder.</label>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-left mt-2">
                                    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMS4xMjQiIGhlaWdodD0iMTUuODQzIiB2aWV3Qm94PSIwIDAgMjEuMTI0IDE1Ljg0MyI+CiAgPHBhdGggaWQ9Ikljb25fYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkYXRhLW5hbWU9Ikljb24gYXdlc29tZS1oYW5kLXBvaW50LXJpZ2h0IiBkPSJNMjEuMTI0LDEwLjFBMS44NiwxLjg2LDAsMCwxLDE5LjI3NiwxMS45aC00LjEyYTIuMDI5LDIuMDI5LDAsMCwxLS4yNiwyLjkyNywxLjg5LDEuODksMCwwLDEtLjY3OSwyLjU3MWMuMzcsMi4wMjctLjkwNSwyLjk0LTMsMi45NC0uMTEzLDAtLjU0OC0uMDA4LS42Ni0uMDA4LTIuNTU3LjAwNy0zLjE3Mi0xLjI4Mi01LjEtMS41ODFhLjk4OS45ODksMCwwLDEtLjgzNi0uOTc4VjEwLjdoMEExLjk4LDEuOTgsMCwwLDEsNS44LDguODljMS4xOTItLjUzNiwzLjkzNy0yLjAyMyw0LjQzNy0zLjE5YTEuNzU5LDEuNzU5LDAsMCwxLDEuNjUtMS4yQTEuOTgyLDEuOTgyLDAsMCwxLDEzLjcsNy4yNmE1LjYwOCw1LjYwOCwwLDAsMS0uNTg0LDEuMDI4aDYuMTU3QTEuODksMS44OSwwLDAsMSwyMS4xMjQsMTAuMVptLTE3LjE2My4wMTR2Ny45MjJhLjk5Ljk5LDAsMCwxLS45OS45OUguOTlhLjk5Ljk5LDAsMCwxLS45OS0uOTlWMTAuMTExYS45OS45OSwwLDAsMSwuOTktLjk5aDEuOThBLjk5Ljk5LDAsMCwxLDMuOTYxLDEwLjExMVpNMi44MDYsMTcuMDQyYS44MjUuODI1LDAsMSwwLS44MjUuODI1QS44MjUuODI1LDAsMCwwLDIuODA2LDE3LjA0MloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTQuNSkiIGZpbGw9IiMwNzI4ZmYiLz4KPC9zdmc+Cg=="
                                        class="img-fluid" />
                                    &nbsp;<a class="rules">Click here to read the Rules and Regulations</a>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-left mt-2 mb-3">
                                    <div>
                                        <label class="mb-0">
                                            <input type="checkbox" id="chkAck" class="CheckboxClass mt-3">
                                            <p class="chkAlign mb-0" for="chkAck" style="margin-top: -27px !important">By checking this box, I acknowledge that I have read,
                                                understand, and agree to the rules and regulations, and will Abide by them.
                                                Please visit the office for a copy of the rules and regulations</p>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
